import * as React from 'react';
import { SFC, Fragment } from 'react';

import { INearbyStop, INearbyRouteServedBy } from '../../../api/types/Nearby';

interface Props {
	results: INearbyStop[];
	accuracy: number;
	loading: boolean;
}

const renderRoute: (route: INearbyRouteServedBy) => JSX.Element = (route: INearbyRouteServedBy) => {
	const style: { [key: string]: string } = {
		color: `#${route.color}`,
		background: `#${route.backgroundColor}`
	};
	return <li style={style} key={`route-${route.id}`} className="served-by">{route.number} <span className="sr">{route.name}</span>
	</li>;
};


const renderServedBy: (routes: INearbyRouteServedBy[]) => JSX.Element = (routes: INearbyRouteServedBy[]) => <ul className="served-by-list">{routes.map(renderRoute)}</ul>;

const renderResult: (stop: INearbyStop) => JSX.Element = (stop: INearbyStop) => <div className="nearby-stop" role="list-item" key={`ns-${stop.id}`}>
	<p className="stop-name">{stop.name}</p>
	<p className="distance">{stop.distance} meters</p>
	{renderServedBy(stop.servedBy)}
	<div className="secondary-actions">
		<a href={`https://www.google.com/maps/dir/?api=1&destination=${stop.latitude},${stop.longitude}&travelmode=walking`}>Directions to stop</a>
	</div>
	<div className="primary-actions">
		<a href={`/maps-and-schedules/bus-stops/info/${stop.id.toLocaleLowerCase()}/`} className="button small light-bg">More about this stop</a>
	</div>
</div>;

const renderResults: (stops: INearbyStop[], accuracy: number) => JSX.Element =
	(stops: INearbyStop[], accuracy: number) => <div className="results">
		<h2>Nearby Stops</h2>
		<div className="results-list" role="list">
			{stops.map(renderResult)}
		</div>
		<p className="accuracy">Location Accuracy {Math.round(accuracy)} meters</p>
	</div>;

const renderLoading: () => JSX.Element = () => <div className="loading-wrap"><p className="loading">Loading...</p></div>

const GeoSearchResult: SFC<Props> = (props: Props) => <div className="search-results geo-search-results" aria-live="polite" aria-atomic="true" id="stopwatch-search-results">
	{props.loading ? renderLoading() : renderResults(props.results, props.accuracy)}
</div>;

export default GeoSearchResult;
