import * as React from 'react';

import { IStopSearchResult } from '../../../api/types/Autocomplete';

import { GenericSearchResults, Props } from './GenericSearchResults';

export class StopSearchResults extends GenericSearchResults<IStopSearchResult> {
	
	public constructor(props: Props<IStopSearchResult>, context: any) {
		super(props, context, 'Bus stops', 'results',
		'No bus stops found.',
		'We were unable to find any bus stops that match your search, but we were able to find some Google Places.');
	}

	protected getLineTwo(result: IStopSearchResult): string {
		return result.result.city;
	}

	protected getStopLink(result: IStopSearchResult): string {
		return StopSearchResults.stopLink(result);
	}
	protected getToLink(result: IStopSearchResult): string {
		return StopSearchResults.toLink(result);
	}
	protected getFromLink(result: IStopSearchResult): string {
		return StopSearchResults.fromLink(result);
	}

	public static stopLink: (result: IStopSearchResult) => string = (result: IStopSearchResult) =>
		`/maps-and-schedules/bus-stops/info/${result.result.id.toLowerCase()}/`;

	public static toLink: (result: IStopSearchResult) => string = (result: IStopSearchResult) =>
	 `/maps-and-schedules/plan-trip/destination/stop/${result.result.id.toLowerCase()}`;

	public static fromLink: (result: IStopSearchResult) => string = (result: IStopSearchResult) =>
		`/maps-and-schedules/plan-trip/origin/stop/${result.result.id.toLowerCase()}`;

}
