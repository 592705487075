import * as React from 'react';
import { FormEvent, KeyboardEvent, PureComponent } from 'react';
import { AutocompleteApi } from '../../api/AutocompleteApi';
import { LocationApi, LocationStatus } from '../../api/LocationApi';
import { NearbyApi } from '../../api/NearbyApi';
import { IPlaceSearchResult, IStopSearchResult, ISuggestResults } from '../../api/types/Autocomplete';
import { INearbyStop } from '../../api/types/Nearby';
import { AutocompleteConstants } from '../../constants/autocomplete';
import { debounce } from '../../generic/helpers/Debounce';
import { ISelectedGeoSearchResult, SelectedSearchResult, SelectedType } from '../types/SelectedSearchResult';
import LocationDisplay from './LocationDisplay';
import LocationInput from './LocationInput';
import GeoSearchResults from './searchResults/GeoSearchResults';
import { PlaceSearchResults } from './searchResults/PlaceSearchResults';
import SearchResultsContainer from './searchResults/SearchResultsContainer';
import { StopSearchResults } from './searchResults/StopSearchResults';

interface Props {
	replaceElement: HTMLElement;
	thriveWord: string;
}

const status: LocationStatus = LocationApi.getStatus();
const locationAvailable: boolean = status !== LocationStatus.denied && status !== LocationStatus.unsupported;
const initialState: {
	locationAvailable: boolean;
	query: string;
	selected: SelectedSearchResult;
	selectedType: SelectedType;
	stopResults: IStopSearchResult[];
	placeResults: IPlaceSearchResult[];
	stopError: boolean;
	placeError: boolean;
	loadingNearby: boolean,
	nearbyStopResults: INearbyStop[];
	formAction: string;
	queryIndex: number;
	resultIndex: number;
} = {
	locationAvailable,
	query: '',
	formAction: '',
	selected: null,
	selectedType: SelectedType.none,
	stopResults: [],
	placeResults: [],
	stopError: false,
	placeError: false,
	loadingNearby: false,
	nearbyStopResults: [],
	queryIndex: 0,
	resultIndex: -1
};
type State = Readonly<typeof initialState>;

export class App extends PureComponent<Props, State> {

	private static readonly SEARCH_DEBOUCNE_TIME: number = 750;
	private static readonly LOCATION_UPDATE_INTERVAL: number = 60000; // 1 minute

	private locationUpdateTimer?: number;
	private readonly locationApi: LocationApi;

	constructor(props: Props, context: any) {
		super(props, context);
		this.locationApi = new LocationApi(this.locationStatusChagned);
		this.state = { ...initialState };
	}

	/**
	 * Render a selected search result.
	 * @returns { JSX.Element }
	 */
	private renderLocationInput: () => JSX.Element = () => {
		if (this.state.selected == null) {
			return <LocationInput
				currentLocationClickCallback={this.currentLocationClick}
				value={this.state.query} queryChangeCallback={this.queryChange}
				locationAvailable={this.state.locationAvailable} />;
		}
		return <LocationDisplay value={this.state.selected.text} clearCallback={this.clearSelected} />;
	};

	/**
	 * Render search results for the query or null if there is already a result.
	 * @returns { JSX.Element }
	 */
	private renderSearchResults: () => JSX.Element = () => {

		if (this.state.selectedType === SelectedType.geo) {
			const { accuracy } = (this.state.selected as ISelectedGeoSearchResult);
			return <GeoSearchResults results={this.state.nearbyStopResults} loading={this.state.loadingNearby} accuracy={accuracy} />;
		}

		if (this.state.selected != null || this.state.query == null || this.state.query.length < AutocompleteConstants.MinQueryLength) {
			return null;
		}

		if ((this.state.placeResults == null || this.state.placeResults.length === 0 || this.state.placeError) &&
			(this.state.stopResults == null || this.state.stopResults.length === 0 || this.state.stopError)) {
			return <div className="search-results error" id="stopwatch-search-results">
				<p className="search-error">
					<i className="material-icons" aria-label="Warning">{String.fromCharCode(57346)}</i>
					<span>
						No bus stops or Google Places found.
						Please try your query again or call us at <a href="tel:+1-217-384-8188">217.384.8188</a>.
					</span>
				</p>
			</div>;
		}

		return <SearchResultsContainer
			query={this.state.query}
			stopResults={this.state.stopResults}
			stopError={this.state.stopError}
			placeResults={this.state.placeResults}
			placeError={this.state.placeError} />;
	};

	/**
	 * Render the app.
	 * @returns { JSX.Element }
	 */
	public render(): JSX.Element {
		return (
			<div className="location-search" role="search" aria-label="Stop Search">
				<form method="GET" action={this.state.formAction} onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
					<div className="hero-wrap">
						<div className="location-search-bar">
							<h1>Helping <strong>{this.props.thriveWord}</strong> thrive</h1>
							<p className="instruct hide-when-offline" id="search-description">
								Enter an address, stop, or place like
								<br />
								<strong>45 E. University</strong>, <strong>Green and Goodwin</strong>, or <strong>Market Place</strong>.
							</p>
							{this.renderLocationInput()}
						</div>
						<svg xmlns="http://www.w3.org/2000/svg" className="swoosh" version="1.1" viewBox="0 0 1000 89">
							<path className="whitespace" d="M0 18C329.9-5.1 719.2 158.9 1000 6v83H0V18z" />
							<path className="stroke" d="M0 18C329.9-5.1 719.2 158.9 1000 6" />
						</svg>
					</div>
					{this.renderSearchResults()}
				</form>
			</div>
		);
	}

	// CALLBACKS

	/**
	 * Update the app based on the query being changed.
	 * Will get search results from Google Places and MTD Stops.
	 * Will timeout if either search takes longer than the the specified timeout.
	 * @param query The query to update.
	 * @returns { Promise<void> }
	 */
	private queryChange: (query: string) => Promise<void> = async (query: string) => {

		const queryIndex: number = this.state.queryIndex + 1;

		if (query == null || query.length < AutocompleteConstants.MinQueryLength) {
			// don't search or show results
			this.setState({
				...this.state,
				query,
				queryIndex,
				formAction: '',
				placeResults: [],
				stopResults: [],
				selected: null
			});

			this.toggleReplaceElement(true);

		} else {
			// update the query before loading search results
			// we want typing to feel instant
			// we don't want a race condition with setting the query state
			// and updating the search results

			// kick off api call, but don't wait for it
			const autocompletePromise: Promise<ISuggestResults> = AutocompleteApi.suggestQuery(window.mtd.searchSessionId, queryIndex, query);

			this.setState({ ...this.state, query, queryIndex }, async () => {

				// load search results
				const searchResult: ISuggestResults = await autocompletePromise;

				const shouldUpdate: boolean = searchResult.queryIndex >= this.state.resultIndex;

				if (shouldUpdate) {
					this.debouncedQueryLog();
					this.setState({
						...this.state,
						resultIndex: searchResult.queryIndex,
						stopResults: searchResult.stopResults,
						placeResults: searchResult.placeResults,
						stopError: searchResult.stopError,
						placeError: searchResult.placeError,
						formAction: App.getDefaultFormUrl(searchResult.stopResults, searchResult.placeResults)
					}, () => this.toggleReplaceElement(false));
				}
			});
		}
	};

	/**
	 * Handle the get current location being clicked.
	 * @returns { void }
	 */
	private currentLocationClick: () => Promise<void> = async () => {
		// update the location on an interval
		this.locationUpdateTimer = window.setInterval(this.updateLocation, App.LOCATION_UPDATE_INTERVAL);
		this.updateLocation(true);
		if (window.mtd && window.mtd.analytics) {
			window.mtd.analytics.LogClick('on', 'nearby_stops');
		}
	};

	private updateLocation: (showLoading: boolean) => Promise<void> = async (showLoading: boolean = false) => {
		let location: Position;
		try {
			location = await this.locationApi.getLocation(true);
		} catch (e) {
			const positionError: PositionError = e as PositionError;
			alert(`Can't get position: ${positionError.message}`);
			if (window.mtd && window.mtd.analytics) {
				window.mtd.analytics.LogError(positionError.message, false);
			}
			throw e;
		}

		const selected: ISelectedGeoSearchResult = {
			latitude: location.coords.latitude,
			longitude: location.coords.longitude,
			accuracy: location.coords.accuracy,
			text: 'Using your device location'
		};

		this.setState({
			...this.state,
			selected,
			selectedType: SelectedType.geo,
			loadingNearby: showLoading
		}, async () => {
			this.toggleReplaceElement(false);
			const nearbyStopResults: INearbyStop[] = await NearbyApi.getNearby(selected.latitude, selected.longitude);
			this.setState({ ...this.state, nearbyStopResults, loadingNearby: false });
		});
	}

	/**
	 * Clear the  selected search result.
	 * @returns { void }
	 */
	private clearSelected: () => void = () => {
		if (this.locationUpdateTimer) {
			window.clearInterval(this.locationUpdateTimer);
			this.locationUpdateTimer = null;
			if (window.mtd && window.mtd.analytics) {
				window.mtd.analytics.LogClick('off', 'nearby_stops');
			}
		}

		this.setState({
			...this.state,
			selected: null,
			selectedType: SelectedType.none,
			nearbyStopResults: [],
			query: ''
		}, () => this.toggleReplaceElement(true));
	};

	/**
	 * Handle the status of the location API changing.
	 * @param status The new status.
	 * @returns { Promise<void> }
	 */
	private locationStatusChagned: (status: LocationStatus) => Promise<void> = (status: LocationStatus) => {
		return new Promise<void>((resolve: () => void) => {
			const locationAvailable: boolean = (status !== LocationStatus.denied) && (status !== LocationStatus.unsupported);
			this.setState({ ...this.state, locationAvailable }, () => {
				resolve();
			});
		});
	};

	private static getDefaultFormUrl: (stopResults: IStopSearchResult[], placeResults: IPlaceSearchResult[]) => string =
		(stopResults: IStopSearchResult[], placeResults: IPlaceSearchResult[]) => {

			if (stopResults != null && stopResults.length > 0) {
				const result: IStopSearchResult = stopResults[0];
				return StopSearchResults.stopLink(result);
			}
			if (placeResults != null && placeResults.length > 0) {
				const result: IPlaceSearchResult = placeResults[0];
				return PlaceSearchResults.stopLink(result);
			}
			return '/';
		};

	private onKeyPress: (event: KeyboardEvent<HTMLFormElement>) => void = (event: KeyboardEvent<HTMLFormElement>) => {
		if (event.which === 13) {
			event.preventDefault();
			if (this.state.formAction != null && this.state.formAction.length > 0 && this.state.formAction !== '/') {
				document.location.href = this.state.formAction;
			}
		}
	};

	private onSubmit: (event: FormEvent<HTMLFormElement>) => void = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (this.state.formAction != null && this.state.formAction.length > 0 && this.state.formAction !== '/') {
			document.location.href = this.state.formAction;
		}
	};

	private toggleReplaceElement: (show: boolean) => void = (show: boolean) =>
		this.props.replaceElement.className = show ? 'show' : 'hide';

	private debouncedQueryLog: () => void = debounce(() => {
		if (window.mtd && window.mtd.analytics && this.state.query && this.state.query.length >= AutocompleteConstants.MinQueryLength) {
			window.mtd.analytics.LogStopSearch(this.state.query);
		}
	}, App.SEARCH_DEBOUCNE_TIME);

}
