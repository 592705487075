import { GenericApi } from './GenericApi';
import { Result, VerifyResult, SetPhoneResult } from './types/Account';
import {IFavoriteStop} from './types/Account';

export module AccountApi {
	const BASE_ACCOUNT_URL: string = '/umbraco/api/account';

	export async function subscribe(id: string): Promise<Result> {
		return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/subscribe?id=${id}`);
	}

	export async function unsubscribe(id: string): Promise<Result> {
		return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/unsubscribe?id=${id}`);
	}

	export async function toggleEmailAlerts(value: boolean): Promise<Result> {
		return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/toggleemailalerts?value=${value}`);
	}

    export async function toggleSecondaryEmailAlerts(value: boolean): Promise<Result> {
	    return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/togglesecondaryemailalerts?value=${value}`);
    }

	export async function toggleSmsAlerts(value: boolean): Promise<Result> {
		return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/togglesmsalerts?value=${value}`);
	}

	export async function setPhone(phone: string): Promise<SetPhoneResult> {
		return await GenericApi.getRequest<SetPhoneResult>(`${BASE_ACCOUNT_URL}/updatephonenumber?number=${phone}`);
	}

	export async function generateSmsConfirmationCode(): Promise<Result> {
		return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/generatesmsconfirmationcode`);
	}

	export async function verifyPhone(code: string): Promise<VerifyResult> {
		return await GenericApi.getRequest<VerifyResult>(`${BASE_ACCOUNT_URL}/validatesmscode?code=${code}`);
    }

    export async function generateEmailConfirmationCode(): Promise<Result> {
        return await GenericApi.getRequest<Result>(`${BASE_ACCOUNT_URL}/generateemailconfirmationcode`);
    }

    export async function verifySecondaryEmail(code: string): Promise <VerifyResult>
	{
        return await GenericApi.getRequest<VerifyResult>(`${BASE_ACCOUNT_URL}/validateemailcode?code=${code}`);
	}

    export async function setSecondaryEmail(email: string): Promise<Result> {
        return await GenericApi.getRequest<SetPhoneResult>(`${BASE_ACCOUNT_URL}/updatesecondaryemail?email=${email}`);
    }

	export async function toggleFavorite(stopId: string, state: boolean): Promise<Result> {
		const url: string = `${BASE_ACCOUNT_URL}/togglefavorite?stopId=${stopId}&state=${state}`;
		return await GenericApi.getRequest<Result>(url);
	}

	export async function getFavorites(): Promise<IFavoriteStop[]> {
		return await GenericApi.getRequest<IFavoriteStop[]>(`${BASE_ACCOUNT_URL}/getFavoriteStops/`);
	}

}
