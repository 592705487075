import * as React from 'react';
import { SFC } from 'react';

import { IPlaceSearchResult, IStopSearchResult } from '../../../api/types/Autocomplete';

import { StopSearchResults } from './StopSearchResults';
import { PlaceSearchResults } from './PlaceSearchResults';

interface Props {
	query: string;
	stopResults: IStopSearchResult[];
	stopError: boolean;
	placeResults: IPlaceSearchResult[];
	placeError: boolean;
}

const SearchResultsContainer: SFC<Props> = (props: Props) => {
	if (props.query == null || props.query.length === 0) {
		return null;
	}

	return <div className={`search-results ${props.stopResults.length > 0 ? 'stop-first' : 'place-first'}`} aria-live="polite" aria-atomic="true" id="stopwatch-search-results">
		<StopSearchResults key={`stop-search-${props.query}`} results={props.stopResults} error={props.stopError} />
		<PlaceSearchResults key={`place-search-${props.query}`} results={props.placeResults} error={props.placeError} />
	</div>;
};

export default SearchResultsContainer;
