import React, { FC, ChangeEvent, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { AutocompleteConstants } from '../../constants/autocomplete';

interface Props {
	locationAvailable: boolean;
	queryChangeCallback: (query: string) => Promise<void>;
	currentLocationClickCallback: () => void;
	value: string;
}

type LocationProps = Pick<Props, 'locationAvailable' | 'currentLocationClickCallback'>;

const LOCATION_BUTTON_LABEL = 'Use GPS Location';

const Location: FC<LocationProps> = (props: LocationProps) => {
	if (props.locationAvailable) {
		return (
			<button
				className="location-button button large dark-bg"
				onClick={props.currentLocationClickCallback}
				title={LOCATION_BUTTON_LABEL}>
				<i className="material-icons" aria-hidden>{String.fromCharCode(57544)}</i>
				<span className="location-label">Nearby</span>
			</button>
		);
	}
	return null;
}

const LocationInput: FC<Props> = (props: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [hasScrolled, setHasScrolled] = useState(false);
	const [charCount, setCharCount] = useState(0);

	useLayoutEffect(() => {
		if (inputRef && inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	useLayoutEffect(() => {
		if (!hasScrolled && inputRef.current && charCount >= AutocompleteConstants.MinQueryLength) {

			const y = inputRef.current.getBoundingClientRect().top + window.scrollY - 6;
			window.scroll({
				top: y,
				behavior: 'smooth'
			});

			setHasScrolled(true);
		}
	}, [inputRef, charCount]);

	function onChange(event: ChangeEvent<HTMLInputElement>): void {
		props.queryChangeCallback(event.target.value);
		setCharCount(event.target.value.length);
	}

	return (
		<div className="location-input hide-when-offline">
			<input
				type="search"
				id="location-search"
				onChange={onChange}
				placeholder="Find stops near..."
				value={props.value}
				autoComplete="off"
				ref={inputRef}
				aria-describedby="search-description"
				aria-label="Find a stop near..."
				{...(props.value && props.value.length > 2 ? { "aria-owns": "stopwatch-search-results" } : {}) }
				aria-autocomplete="list"
			/>
			<Location {...props} />
		</div>
	);
};

export default LocationInput;
