export enum LocationStatus {
	unknown,
	unsupported,
	denied,
	unavailable
}

export class LocationApi {
	private _status: LocationStatus;

	/**
	 * The current status of the location API.
	 * @returns { LocationStatus } 
	 */
	public get status(): LocationStatus {
		return this._status;
	}

	private readonly statusChange: (status: LocationStatus) => Promise<void>;

	/**
	 * Create a new LocationApi
	 * @param statusChange a callback function that should be executed if the status changes.
	 */
	constructor(statusChange: (status: LocationStatus) => Promise<void> = null) {
		this._status = LocationApi.getStatus();
		this.statusChange = statusChange;
	}

	/**
	 * Get the devices current position
	 * @param enableHighAccuracy If high accuracy location should be requested.
	 * @param timeout The maximum time to wait for a location
	 * @param maximumAge How old a location can be from the cache.
	 */
	public async getLocation(enableHighAccuracy: boolean = true, timeout: number = Infinity, maximumAge: number = 60000): Promise<Position> {

		return new Promise<Position>((resolve: (position: Position) => void, reject: (reason: PositionError) => void) => {

			const success: PositionCallback = (p: Position) => resolve(p);

			const error: PositionErrorCallback = (e: PositionError) => {
				// check error states
				if (e.code === e.PERMISSION_DENIED) {
					this.updateStatus(LocationStatus.denied);
				} else if (e.code === e.POSITION_UNAVAILABLE) {
					this.updateStatus(LocationStatus.unavailable);
				}
				reject(e);
			};

			// get the position
			navigator
				.geolocation
				.getCurrentPosition(success, error, { enableHighAccuracy, timeout, maximumAge });
		});
	}

	/**
	 * Update the location status and call the change event if available.
	 * @param status The new status.
	 * @returns { void } 
	 */
	private updateStatus: (status: LocationStatus) => void = (status: LocationStatus) => {
		if (this.status !== status) {
			this._status = status;
		}
		if (this.statusChange) {
			this.statusChange(status);
		}
	}

	public static getStatus: () => LocationStatus = () => {
		if (!('geolocation' in navigator)) {
			return LocationStatus.unsupported;
		}
		return LocationStatus.unknown;
	}

}
