import { GenericApi } from './GenericApi';
import { ISuggestResults } from './types/Autocomplete';

export module AutocompleteApi {
	const SEARCH_API_VERSION: string = 'v1.0.2';
	const SEARCH_BASE_URL: string = `https://search.mtd.org/${SEARCH_API_VERSION}`;
	const SUGGEST_BASE_URL: string = `${SEARCH_BASE_URL}/website/suggest`;
	const PLACE_DETAILS_BASE_URL: string = `${SEARCH_BASE_URL}/google/places/details`;

	function encode(url: string): string {
		const replaced: string = url
			.replace(/[\/]+/g, ' ')
			.replace(/[&]+/g, 'and')
			.trim();

		return encodeURIComponent(replaced);
	}

	function buildUrl(baseUrl: string, sessionId: string, count: number, query: string): string {
		return `${baseUrl}/${sessionId}/${count}/${encode(query)}`;
	}

	export async function suggestQuery(sessionId: string, queryIndex: number,query: string): Promise<ISuggestResults> {
		return await GenericApi.getRequest<ISuggestResults>(buildUrl(SUGGEST_BASE_URL, sessionId, queryIndex, query));
	}

}
