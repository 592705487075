import * as React from 'react';
import { PureComponent, MouseEvent } from 'react';

import { IQueryHighlight } from '../../../api/types/Autocomplete';
import { ILinkInfo } from '../../types/ILinkInfo';

import QueryHighlight from '../../../generic/components/QueryHighlight';

interface Props {
	id: string;
	highlights: IQueryHighlight[];
	lineTwo: string;
	linkInfo: ILinkInfo;
}

const initialState: { selected: number } = { selected: 0 };
type State = Readonly<typeof initialState>;

export class SearchResultLine extends PureComponent<Props, State> {
	constructor(props: Props, context: any) {
		super(props, context);
		this.state = { ...initialState };
	}

	public render(): JSX.Element {
		return <div
			className="search-result"
			role="listitem"
			onClick={this.resultClick}>
			<div className="display">
				<QueryHighlight wrapperClass="line-one" highlights={this.props.highlights} />
				<div className="line-two">{this.props.lineTwo}</div>
			</div>
			<div className="actions" onMouseOut={() => this.setState({ ...this.state, selected: 0 })}>
				<div className="secondary-actions">
					{this.renderLink(this.props.linkInfo.fromLink, this.props.linkInfo.fromText, 0)}
					{this.renderLink(this.props.linkInfo.toLink, this.props.linkInfo.toText, 1)}
				</div>
				<div className="primary-action">
					{this.renderLink(this.props.linkInfo.stopLink, this.props.linkInfo.stopText, 2, true)}
				</div>
			</div>
		</div>;
	}

	private renderLink: (href: string, text: string, index: number, button?: boolean) => JSX.Element = (href: string, text: string, index: number, button: boolean = false) => {
		const selected: boolean = index === this.state.selected;
		return <a href={href} className={`${selected ? 'hover' : ''} ${button ? 'button small light-bg' : ''}`} onMouseOver={() => this.changeHover(index)}>{text}</a>;
	}

	private resultClick: (clickEvent: MouseEvent<HTMLElement>) => void = (clickEvent: MouseEvent<HTMLElement>) => {
		clickEvent.preventDefault();

		// if they click an actual link, go to that link
		// if they click somewhere else in the box, go to the stoplink
		const a: HTMLAnchorElement = clickEvent.target as HTMLAnchorElement;
		if (a != null && a.href != null && a.href.length > 0) {
			document.location.href = a.href;
		} else {
			document.location.href = this.props.linkInfo.stopLink;
		}
	};

	private changeHover: (index: number) => void = (index: number) => {
		if (this.state.selected !== index) {
			this.setState({ ...this.state, selected: index });
		}
	};
}
