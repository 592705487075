import * as React from 'react';
import { PureComponent } from 'react';
import { SearchResult } from '../../../api/types/Autocomplete';
import { ILinkInfo } from '../../types/ILinkInfo';
import { SearchResultLine } from './SearchResult';

export interface Props<T extends SearchResult> {
	results: T[];
	error: boolean;
}

export abstract class GenericSearchResults<T extends SearchResult> extends PureComponent<Props<T>> {

	private readonly title: string;
	private readonly resultClass: string;
	private readonly errorLine1: string;
	private readonly errorLine2: string;

	protected constructor(props: Props<T>, context: any,
		title: string, resultClass: string = 'results',
		errorLine1: string, errorLine2: string) {
		super(props, context);
		this.title = title;
		this.resultClass = resultClass;
		this.errorLine1 = errorLine1;
		this.errorLine2 = errorLine2;
	}

	public render(): JSX.Element {
		return <div className={this.resultClass}>
			<h2>{this.title}</h2>
			{this.renderResults()}
		</div>;
	}

	private renderResults(): JSX.Element {
		if (this.props.error || !(this.props.results && Array.isArray(this.props.results))) {
			return <div className="result-error">
				<p>An error occoured loading search results.</p>
			</div>;
		} else if (this.props.results.length === 0) {
			return <div className="results-empty">
				<p className="empty1">{this.errorLine1}</p>
				<p className="empty2">{this.errorLine2}</p>
			</div>;
		}

		let results: JSX.Element[] = [];
		try {
			results = this.props.results.map(
				(r: T) => {
					const linkInfo: ILinkInfo = {
						stopText: this.getStopText(),
						stopLink: this.getStopLink(r),
						toText: this.getToText(),
						toLink: this.getToLink(r),
						fromText: this.getFromText(),
						fromLink: this.getFromLink(r)
					};
					return <SearchResultLine
						key={`sr-line-${r.result.id}`}
						id={r.result.id}
						highlights={r.queryHighlight}
						lineTwo={this.getLineTwo(r)}
						linkInfo={linkInfo} />;
				});
		} catch (ex) {
			if (window.mtd && window.mtd.analytics) {
				window.mtd.analytics.LogError(ex, false);
			}
			console.error('can\'t map results', this.props);
			console.error('e', ex);
		}

		return <div role="list" className="list">
			{results}
		</div>;
	};


	protected getStopText(): string {
		return 'More about this stop';
	}

	protected getFromText(): string {
		return 'Travel from here';
	}

	protected getToText(): string {
		return 'Travel to here';
	}

	protected abstract getLineTwo(result: T): string;
	protected abstract getStopLink(result: T): string;
	protected abstract getToLink(result: T): string;
	protected abstract getFromLink(result: T): string;

}
