import * as React from 'react';

import { IPlaceSearchResult } from '../../../api/types/Autocomplete';

import { GenericSearchResults, Props } from './GenericSearchResults';

export class PlaceSearchResults extends GenericSearchResults<IPlaceSearchResult> {

	public constructor(props: Props<IPlaceSearchResult>, context: any) {
		super(props, context, 'Places', 'places',
			'No Google Places found.',
			'We were unable to find any Google Places that match your search, but we were able to find bus stops.');
	}

	protected getStopText(): string {
		return 'Find stops nearby';
	}

	protected getLineTwo(result: IPlaceSearchResult): string {
		return result.result.secondary;
	}

	protected getStopLink(result: IPlaceSearchResult): string {
		return PlaceSearchResults.stopLink(result);
	}

	protected getToLink(result: IPlaceSearchResult): string {
		return PlaceSearchResults.toLink(result);
	}

	protected getFromLink(result: IPlaceSearchResult): string {
		return PlaceSearchResults.fromLink(result);
	}

	public static stopLink: (result: IPlaceSearchResult) => string = (result: IPlaceSearchResult) =>
	 `/maps-and-schedules/bus-stops/nearby/${result.result.id}`;

	public static toLink: (result: IPlaceSearchResult) => string = (result: IPlaceSearchResult) =>
		`maps-and-schedules/plan-trip/destination/place/${result.result.id}?name=${encodeURIComponent(result.result.display)}`;

	public static fromLink: (result: IPlaceSearchResult) => string = (result: IPlaceSearchResult) =>
		`maps-and-schedules/plan-trip/origin/place/${result.result.id}?name=${encodeURIComponent(result.result.display)}`;

}
