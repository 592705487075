import { GenericApi } from './GenericApi';
import { INearbyStop } from './types/Nearby';

export module NearbyApi {
	const BASE_URL: string = '/umbraco/api/nearby';

	export async function getNearby(lat: number, lon: number): Promise<INearbyStop[]> {
		return await GenericApi.getRequest<INearbyStop[]>(`${BASE_URL}/GetNearbyStops/?latitude=${lat}&longitude=${lon}`);
	}
}
