import * as React from 'react';
import * as reactDom from 'react-dom';
import { App as LocationApp } from './homepage/components/App';
import { App as Favs } from './homepage/components/favs/App';

(() => {
	//SEARCH
	const searchDomElement = document.getElementById('search-app');
	const replaceDomElement = document.getElementById('homepage-content');
	if (searchDomElement && replaceDomElement) {
		const searchReactElement = (
			<LocationApp
				replaceElement={replaceDomElement}
				thriveWord={window.mtd.thriveWord}
			/>
		);
		reactDom.render(searchReactElement, searchDomElement);
	}

	// FAVS
	const favsDomElement = document.getElementById('favs-app');
	if (favsDomElement) {
		const favsReactApp = (
			<Favs
				initialStops={window.mtd.favs}
				gridElementName="homepage-grid"
			/>
		);
		reactDom.render(favsReactApp, favsDomElement);
	}
})();
