import * as React from 'react';
import { SFC } from 'react';

const BUTTON_LABEL: string = 'Clear GPS location';

interface Props {
	value: string;
	clearCallback: () => void;
}

const LocationDisplay: SFC<Props> = (props: Props) =>
(<div className="location-input hide-when-offline">
	<input
		type="search"
		value={props.value}
		id="location-input"
		aria-aria-describedby="search-description"
		aria-label="Find stops near..."
		placeholder="Find stops near..."
		aria-owns="stopwatch-search-results"
		disabled />
	<button
		className="button large location-button dark-bg"
		onClick={props.clearCallback}
		title={BUTTON_LABEL}
	>
		<i className="material-icons" aria-hidden>{String.fromCharCode(57543)}</i>
		<span className="location-label">Nearby</span>
	</button>
</div>);

export default LocationDisplay;
