import * as React from 'react';
import { SFC } from 'react';

interface Props {
	useId: string;
}

const cleanId: (id: string) => string = (id: string) => {
	if (id.charAt(0) === '#') {
		return id;
	}
	return `#${id}`;
}

const getHtml: (id: string) => string = (id: string) => `<use xmlns: xlink="http://www.w3.org/1999/xlink" xlink:href="${cleanId(id)}"></use>`;

const SvgUse: SFC<Props> = (props: Props) => <svg dangerouslySetInnerHTML={{ __html: getHtml(props.useId) }}/>;

export default SvgUse;
