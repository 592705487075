export enum SelectedType {
	none,
	stop,
	place,
	geo
}

interface ISelectedSearchResult {
	text: string;
}

export interface ISelectedStopSearchResult extends ISelectedSearchResult {
	id: string;
}

export interface ISelectedPlaceSearchResult extends ISelectedSearchResult {
	id: string;
	latitude?: number;
	longitude?: number;
	isOpen?: boolean;
}

export interface ISelectedGeoSearchResult extends ISelectedSearchResult {
	latitude: number;
	longitude: number;
	accuracy: number;
}

export type SelectedSearchResult = ISelectedStopSearchResult | ISelectedPlaceSearchResult | ISelectedGeoSearchResult;