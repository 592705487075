import * as React from 'react';
import { PureComponent } from 'react';

import { Events } from '../../../constants/events';

import { AccountApi } from '../../../api/AccountApi';

import { IFavoriteStop } from '../../../api/types/Account';

import FavoriteStop from './FavoriteStop';
import TooMany from './TooMany';
import SvgUse from '../../../generic/components/SvgUse';

interface Props {
	initialStops: IFavoriteStop[];
	gridElementName: string;
}

interface PropState {
	favorites: IFavoriteStop[];
	tooMany: boolean;
}

type State = Readonly<PropState>;

export class App extends PureComponent<Props, State> {

	private readonly gridElement: HTMLElement;

	private static readonly MAX_FAVS: number = 20;

	constructor(props: Props, context: any) {
		super(props, context);

		const favorites: IFavoriteStop[] = this.takeN(props.initialStops, App.MAX_FAVS);
		const tooMany: boolean = this.hasTooMany(props.initialStops);

		this.state = {
			favorites,
			tooMany
		};

		this.gridElement = document.getElementById(props.gridElementName);
		window.addEventListener(Events.UserAuthEventName, this.handleUserLogin);
	}

	public render(): JSX.Element[] {
		if (this.state.favorites == null || this.state.favorites.length === 0) {
			return null;
		}

		return [
			<h2 className="two-color-heading favorites-heading" key="heading">
				<span>Favorite <b>Stops.</b></span>
				<SvgUse useId="favicon" key="favicon" />
			</h2>,
			<div className="results fav-list" role="list" key="results">
				{this
					.state
					.favorites
					.map((f: IFavoriteStop) => <FavoriteStop key={f.id} {...f} />)}
			</div>,
			<TooMany key="too-many" show={this.state.tooMany} count={App.MAX_FAVS.toLocaleString()} />
		];
	}

	private handleUserLogin: () => Promise<void> = async () => {
		const favoritesFromServer: IFavoriteStop[] = await AccountApi.getFavorites();

		const tooMany: boolean = this.hasTooMany(favoritesFromServer);
		const favorites: IFavoriteStop[] = this.takeN(favoritesFromServer, App.MAX_FAVS);

		this.gridElement.classList.add('has-favorites');
		this.setState({ ...this.state, favorites, tooMany });
	}

	private hasTooMany: (favorites: IFavoriteStop[]) => boolean = (favorites: IFavoriteStop[]) => {
		if (favorites != null) {
			return favorites.length > App.MAX_FAVS;
		}
		return false;
	}

	private takeN: (favorites: IFavoriteStop[], n: number) => IFavoriteStop[] = (favorites: IFavoriteStop[], n: number) => {
		if (favorites != null && favorites.length >= n) {
			return favorites.slice(0, n);
		}
		return favorites;
	}
}
