import React, { FC } from 'react';
import { IFavoriteStop } from '../../../api/types/Account';

interface Props extends IFavoriteStop {}

const FavoriteStop: FC<Props> = (props: Props) => (
	<div className="fav" role="listitem">
		<h3>{props.name}</h3>
		<h4>{props.city}</h4>
		<a href={`/maps-and-schedules/bus-stops/info/${props.id.replace(':', '-').toLowerCase()}/`} className="button light-bg">More about this stop</a>
	</div>
);

export default FavoriteStop;
