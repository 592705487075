import * as React from 'react';
import { SFC } from 'react';


interface Props {
	show: boolean;
	count: string;
}

const TooMany: SFC<Props> = (props: Props) => {
	if (props.show) {
		return <p className="too-many"><strong>Where are the rest of my favorites?</strong> Only {props.count} favorites can be shown on the homepage. Try unfavoriting a few stops to see more.</p>;
	}
	return null;
};

export default TooMany;
