import * as React from 'react';
import { SFC } from 'react';

import {IQueryHighlight} from '../../api/types/Autocomplete';

interface Props {
	wrapperClass: string;
	highlights: IQueryHighlight[];
}

const QueryHighlight: SFC<Props> = (props: Props) => {
	const renderHighlight: (highlight: IQueryHighlight, index: number) => JSX.Element = (highlight: IQueryHighlight, index: number) => {
		const elements: JSX.Element[] = [];
		if (highlight.highlightPart) {
			const key: string = `wordpart-hl-${index}-${highlight.highlightPart}`;
			elements.push(<b key={key}>{highlight.highlightPart}</b>);
		}
		if (highlight.noHighlightPart) {
			const key: string = `wordpart-nhl-${index}-${highlight.noHighlightPart}`;
			elements.push(<span key={key}>{highlight.noHighlightPart}</span>);
		}
		if (elements.length > 0) {
			const key: string = `word-${index}-${highlight.highlightPart}-${highlight.noHighlightPart}`;
			return <span className="word" key={key}>{elements}</span>;
		}
		return null;
	};

	return <div className={props.wrapperClass}>{props.highlights.map(renderHighlight)}</div>;

};

export default QueryHighlight;
